import React from "react"
// import Helmet from "react-helmet"
import { useStaticQuery, graphql } from 'gatsby'
import Header from "./Header"
import Footer from "./Footer"

// require("../styles/index.scss")

const WrapperContext = React.createContext()

const Layout = ({ children, pageContext: { template } }) => {
    const { options } = useStaticQuery(query)
    // console.log(options)
    return (
        <WrapperContext.Provider value={{ options }}>
            <div id="page">
                {template === "default" &&
                    <main>{children}</main>
                }

                {template == 'matterport' &&
     
                    <div className="x">
                        <Header />
                        <main>
                            {children}
                            {/* <Footer /> */}
                        </main>
                    </div>
          
                }
            

            </div>
        </WrapperContext.Provider>
    )
};

export { WrapperContext, Layout };

const query = graphql`
    query {
        options: prismicOptions {
            ..._prismicOptions
        }
    }
`
