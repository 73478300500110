import React, { useContext } from 'react';
//import { StaticQuery, graphql } from "gatsby"
// import { WrapperContext } from './Layout'
// import { Link } from 'gatsby';
import Burger from './ui/Burger'
import { useEffect } from 'react';
import { useState } from 'react';

const headerTop = require('../images/header-top.svg')
const headerBottom = require('../images/header-bottom.svg')

const Header = () => {
    // const _WrapperContext = useContext(WrapperContext)
    const [active, setActive] = useState(false);

    useEffect(() => {
        const token = PubSub.subscribe("BURGER", (e, value) => {
            console.log(value)
            setActive(value)
        })
        return () => {
            PubSub.unsubscribe(token)
        }
    }, []);

    const headerClass = active 
        ? "is-active"
        : ""
    return(
        <>
            <header className={headerClass}>
                <div className="x xdc xjb">
                    <div className="header-top">
                        <img src={headerTop} alt=""/>
                    </div>
                    <div className="header-bottom">
                        <img src={headerBottom} alt=""/>
                    </div>
                </div>
                
                {/* <Link to="/">{_WrapperContext.options.data.title.text}</Link> */}
            </header>
            <Burger />
        </>
    )
}

export default Header;
